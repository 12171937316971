import React, { useEffect, useState, useCallback } from 'react';
import { useStore } from '@nanostores/react';
import { globalStore } from '~/store';
import { getHash } from '~/utils';
import { fold } from 'json-origami';
import debounce from 'lodash-es/debounce';

const sortObjectByKeys = (obj: Record<string, any>): Record<string, any> => {
  const sortedKeys = Object.keys(obj).sort();
  const result: Record<string, any> = {};
  sortedKeys.forEach((key) => {
    result[key] = obj[key];
  });
  return result;
};

const HashComponent: React.FC = () => {
  const store = useStore(globalStore);
  const [combinedHash, setCombinedHash] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const calculateCombinedHash = useCallback(
    debounce(async (store) => {
      const keys = ['audiofp', 'fonts', 'canvas', 'device', 'navigator', 'webgl', 'speech_synthesis'];
      const combinedData = keys.reduce((acc, key) => {
        if (store[key] && store[key].hash) {
          acc[key] = store[key].hash;
        }
        return acc;
      }, {});

      const hash = await getHash(combinedData);
      setCombinedHash(hash);
      setIsLoading(false);
    }, 1000),
    [],
  );

  useEffect(() => {
    if (store.webrtc !== undefined) {
      calculateCombinedHash(store);
    }
  }, [store]);

  return <code>{isLoading ? <div className="loader">Вычисляем хэш...</div> : <pre>{combinedHash}</pre>}</code>;
};

export default HashComponent;
